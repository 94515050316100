import { useEffect, useContext, ChangeEvent, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Table, Input, Popconfirm, TablePaginationConfig, Checkbox } from 'antd';
import { DeleteOutlined, SearchOutlined, PlusCircleOutlined, ReloadOutlined } from '@ant-design/icons';
import { getColumns } from './helpers';
import { FilterValue, Key, SorterResult } from 'antd/es/table/interface';
import { Track } from './types';
import { selectTrackState } from './tracksSlice';
import { ActionsContext } from '../../Actions';
import isArray from 'lodash/isArray';
import { TrackModal } from '../TrackModal';
import { useUserCustomClaims } from 'hooks';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { VybeCollectionSelector } from './VybeCollectionSelector';

export const Tracks = () => {
  const { actions } = useContext(ActionsContext);

  const userCustomClaims = useUserCustomClaims();

  const {
    tracks,
    isLoading,
    selectedRowKeys,
    pagination,
    isTrackModalOpen,
    isBulkTrackActive,
    isVybeCollectionDrawerOpen,
  } = useSelector(selectTrackState);

  const onSelectChange = (newlySelectedRowKeys: Key[]) => {
    actions.tracks.setSelectedRowKeys(newlySelectedRowKeys);
  };

  const initializeTrackForm = () => {
    actions.tracks.setBulkTrackActiveState(false);
    actions.tracks.setTrackModalVisibilityState(true);
    fetchTracks();
  };

  const onTrackModalClose = () => {
    actions.tracks.setTrackModalVisibilityState(false);
  };

  const handleBulkTrackToggle = (e: CheckboxChangeEvent) => {
    const isBulkTrackOptionActive = e.target.checked;
    actions.tracks.setBulkTrackActiveState(isBulkTrackOptionActive);
    fetchTracks();
  };

  const handleSearchFilter = (event: ChangeEvent<HTMLInputElement>) => {
    const searchString = event.target.value || '';
    actions.tracks.setSearchString(searchString);
  };

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<Track> | SorterResult<Track>[],
  ) => {
    const resolvedSorter = sorter as SorterResult<Track>;

    actions.tracks.setPagination(pagination);

    if (resolvedSorter.column) {
      actions.tracks.setSorter({
        field: resolvedSorter.field,
        order: resolvedSorter.order,
      });
    } else {
      actions.tracks.setSorter({
        field: 'created_at',
        order: 'descend',
      });
    }

    actions.tracks.setFilters(filters);

    fetchTracks();
  };

  const fetchTracks = useCallback(() => actions.tracks.fetchTracks(), [actions.tracks]);

  useEffect(() => {
    fetchTracks();
  }, [fetchTracks]);

  const deleteSeletedRowKeys = () => {
    const idsToBeDeleted = selectedRowKeys as number[];
    if (isArray(selectedRowKeys) && selectedRowKeys.length > 0) {
      if (isBulkTrackActive) {
        actions.tracks.deleteBulkTracks(idsToBeDeleted);
      } else {
        actions.tracks.deleteTracks(idsToBeDeleted);
      }
    }
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const canDeleteTracks = userCustomClaims.isAdmin && userCustomClaims.isSuperAdmin;

  return (
    <div style={{ padding: '0 50px' }}>
      <div style={{ textAlign: 'left' }}>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        {canDeleteTracks && isArray(selectedRowKeys) && selectedRowKeys.length > 0 && (
          <Popconfirm
            title="Are you sure?"
            onConfirm={deleteSeletedRowKeys}
            placement="right"
            okText="Yes"
            cancelText="No"
            disabled={!tracks.length}
          >
            <DeleteOutlined style={{ cursor: 'pointer' }} />
          </Popconfirm>
        )}
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <PlusCircleOutlined onClick={() => initializeTrackForm()} style={{ cursor: 'pointer' }} />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <ReloadOutlined onClick={fetchTracks} style={{ cursor: 'pointer' }} />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <Input
          suffix={<SearchOutlined />}
          defaultValue=""
          placeholder="Search Filter"
          onChange={handleSearchFilter}
          style={{ width: 200 }}
        />
        <Checkbox
          checked={isBulkTrackActive}
          value={isBulkTrackActive}
          onChange={handleBulkTrackToggle}
          style={{ marginLeft: 10 }}
        >
          Bulk Tracks Only
        </Checkbox>
      </div>
      {!tracks.length && <div style={{ marginBottom: 55 }}></div>}
      <Table
        rowKey="id"
        rowSelection={canDeleteTracks ? rowSelection : undefined}
        onChange={handleTableChange}
        pagination={{ ...pagination, showSizeChanger: true }}
        loading={isLoading}
        size="small"
        dataSource={tracks}
        columns={getColumns(actions, userCustomClaims.isSuperAdmin)}
        scroll={{ x: 1500 }}
      />
      {isTrackModalOpen && <TrackModal isModalOpen={isTrackModalOpen} onModalClose={onTrackModalClose} />}
      {isVybeCollectionDrawerOpen && <VybeCollectionSelector />}
    </div>
  );
};
