import { initializeApp, FirebaseApp, FirebaseOptions } from 'firebase/app';
import { getAuth, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import {
  getStorage,
  ref,
  getDownloadURL,
  uploadBytesResumable,
  UploadTaskSnapshot,
  StorageError,
} from 'firebase/storage';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { firebaseConfig, tracksBucket } from './config';

export class FirebaseService {
  public static firebase: FirebaseApp;

  constructor(options: FirebaseOptions = {}) {
    FirebaseService.firebase = initializeApp(Object.assign({}, firebaseConfig, options), 'Aqueduct');
  }

  public static getAuth = () => {
    const auth = getAuth(FirebaseService.firebase);
    return auth;
  };

  private static getStorage = (bucket = tracksBucket) => {
    const storage = getStorage(FirebaseService.firebase, bucket);
    return storage;
  };

  public static getStorageRef = (fileName: string, bucket = tracksBucket) => {
    const storageRef = ref(FirebaseService.getStorage(bucket), fileName);
    return storageRef;
  };

  public static getDownloadUrl = async (fileName: string, bucket = tracksBucket) => {
    const downloadUrl = await getDownloadURL(FirebaseService.getStorageRef(fileName, bucket));
    return downloadUrl;
  };

  public static getFunctions = <T, V>(functionName: string) => {
    const functions = getFunctions(FirebaseService.firebase);
    return httpsCallable<T, V>(functions, functionName);
  };

  public static uploadFile = async (
    file: File,
    fileName: string,
    bucket: string,
    onUploadStateChange: (snapshot: UploadTaskSnapshot) => void,
    onUploadErrorHandler: (error: StorageError) => void,
    onUploadComplete: (snapshot: UploadTaskSnapshot) => void,
  ) => {
    const uploadTask = uploadBytesResumable(FirebaseService.getStorageRef(fileName, bucket), file);

    // Register three observers:
    // 1. 'state_changed' observer, called any time the state changes
    // 2. Error observer, called on failure
    // 3. Completion observer, called on successful completion
    uploadTask.on('state_changed', onUploadStateChange, onUploadErrorHandler, () =>
      onUploadComplete(uploadTask.snapshot),
    );
  };

  public static signInWithEmailAndPassword = (email: string, password: string) => {
    const auth = FirebaseService.getAuth();
    return signInWithEmailAndPassword(auth, email, password);
  };

  public static logout = () => {
    const auth = FirebaseService.getAuth();
    return signOut(auth);
  };

  public static currentUser = () => {
    const auth = FirebaseService.getAuth();
    return auth.currentUser;
  };

  public static getCurrentUserCustomClaims = async () => {
    const auth = FirebaseService.getAuth();

    const idTokenResult = await auth.currentUser?.getIdTokenResult();

    return idTokenResult?.claims;
  };
}
