import { useContext, useEffect, useMemo, useState } from 'react';
import { Drawer, Popconfirm, Select } from 'antd';
import { ActionsContext } from 'Actions';
import { useSelector } from 'react-redux';
import { selectTrackState } from './tracksSlice';
import { selectVybeCollectionState } from 'containers/VybeCollections/vybeCollectionsSlice';
import { VybeCollectionCreateObject } from 'containers/VybeCollections/types';

export const VybeCollectionSelector = () => {
  const [open, setOpen] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [selectedOption, setSelectedOption] = useState<number>();
  const { actions } = useContext(ActionsContext);

  const {
    isVybeCollectionDrawerOpen,
    isLoading: isTracksLoading,
    currentlyEditedTrack,
  } = useSelector(selectTrackState);
  const { vybeCollections, isLoading: isVybeCollectionsLoading } = useSelector(selectVybeCollectionState);

  const onDrawerClose = () => {
    actions.tracks.setVybeCollectionDrawerVisibilityState(false);
    actions.tracks.setCurrentlyEditedTrack();
  };

  const vybeCollectionTrackIsMemberOf = useMemo(() => {
    if (currentlyEditedTrack) {
      return vybeCollections.filter((vc) =>
        vc.vybe_collection_tracks?.map((t) => t.track_id).includes(currentlyEditedTrack.id),
      );
    }
    return [];
  }, [currentlyEditedTrack, vybeCollections]);

  const options = useMemo(() => {
    return vybeCollections.map((vc) => ({ value: vc.id, label: vc.name }));
  }, [vybeCollections]);

  const vybeCollectionOptionsTrackIsMemberOf: {
    value: number;
    label: string;
  }[] = options.filter((o) => vybeCollectionTrackIsMemberOf.map((vc) => vc.id).includes(o.value));

  const handleConfirm = () => {
    setOpen(false);
    const vybeCollectionToUpdate = vybeCollections.find((vc) => vc.id === selectedOption);

    if (isAdding) {
      if (vybeCollectionToUpdate?.vybe_collection_tracks && currentlyEditedTrack) {
        const updatedVybeCollectionTracks = vybeCollectionToUpdate.vybe_collection_tracks.concat({
          track_id: currentlyEditedTrack?.id,
          vybe_collection_id: vybeCollectionToUpdate.id,
          tracks: currentlyEditedTrack,
        });

        const values: VybeCollectionCreateObject = {
          ...vybeCollectionToUpdate,
        };
        const resolvedValues = {
          ...values,
          track_ids: updatedVybeCollectionTracks.map(({ tracks }) => tracks.id) || [],
        };

        actions.vybeCollections.updateVybeCollection({
          ...resolvedValues,
          id: selectedOption,
        });
      }
    } else {
      if (vybeCollectionToUpdate?.vybe_collection_tracks && currentlyEditedTrack) {
        const updatedVybeCollectionTracks = vybeCollectionToUpdate.vybe_collection_tracks.filter(
          (vc) => vc.tracks.id !== currentlyEditedTrack.id,
        );

        const values: VybeCollectionCreateObject = {
          ...vybeCollectionToUpdate,
        };
        const resolvedValues = {
          ...values,
          track_ids: updatedVybeCollectionTracks.map(({ tracks }) => tracks.id) || [],
        };

        actions.vybeCollections.updateVybeCollection({
          ...resolvedValues,
          id: selectedOption,
        });
      }
    }
  };

  useEffect(() => {
    actions.vybeCollections.fetchVybeCollections();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Drawer
        title="Edit Track's Vybe Collection"
        placement="right"
        closable={false}
        onClose={onDrawerClose}
        open={isVybeCollectionDrawerOpen}
        key="right"
      >
        <Popconfirm
          title={`Are you sure you want to ${isAdding ? 'add' : 'remove'}?`}
          onConfirm={handleConfirm}
          onCancel={() => setOpen(false)}
          open={open}
          placement="left"
          okText="Yes"
          cancelText="No"
        >
          <Select
            style={{ width: '100%' }}
            mode="multiple"
            value={vybeCollectionOptionsTrackIsMemberOf}
            placeholder="Search Vybe Collection"
            options={options}
            loading={isVybeCollectionsLoading || isTracksLoading}
            onSelect={(option) => {
              setOpen(true);
              if (!vybeCollectionOptionsTrackIsMemberOf.map((vc) => vc.value).includes(option as unknown as number)) {
                setIsAdding(true);
                setSelectedOption(option as unknown as number);
              }
            }}
            onDeselect={(option) => {
              setOpen(true);
              if (vybeCollectionOptionsTrackIsMemberOf.map((vc) => vc.value).includes(option as unknown as number)) {
                setIsAdding(false);
                setSelectedOption(option as unknown as number);
              }
            }}
          />
        </Popconfirm>
      </Drawer>
    </>
  );
};
