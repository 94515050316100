import { FC, useEffect, useState, useMemo, useContext, useCallback } from 'react';
import { useSelector } from 'react-redux';
import dayjs, { Dayjs } from 'dayjs';
import {
  Modal,
  Button,
  Switch,
  Form,
  Input,
  InputNumber,
  DatePicker,
  Upload,
  Select,
  message,
  Divider,
  Space,
  Image,
  Tag,
  Drawer,
  Flex,
} from 'antd';
import { ValidateErrorEntity } from 'rc-field-form/lib/interface';
import {
  CamelotKeys,
  SpotifyTrackResult,
  Track,
  TrackCreateObject,
  TracksContentRating,
  genreDisplayNames,
} from '../Tracks/types';
import { FirebaseService } from 'utils/firebase';
import { InboxOutlined, CloudUploadOutlined } from '@ant-design/icons';
import { AudioPlayer } from '../../components/PeakJsPlayer';
import { SpotifyModal } from '../SpotifyModal';
import { selectTrackState } from '../Tracks/tracksSlice';
import { readTrackBufferDetails, readID3Tags, throttleMessageDisplay, copyTextToClipboard } from '../../utils';
import { videosUploadBucket } from 'utils/config';
import { ActionsContext } from '../../Actions';
import { VideoClip } from '../../models';
import './TrackModal.css';

const { Dragger } = Upload;

const SpotifyLogoURL =
  'https://upload.wikimedia.org/wikipedia/commons/thumb/8/84/Spotify_icon.svg/1982px-Spotify_icon.svg.png';

interface TrackModalProps {
  isModalOpen?: boolean;
  onModalOpen?: () => void;
  onModalClose?: () => void;
}

const CONTENT_RATING_TOOLTIP = `
${TracksContentRating.EXPLICIT}: (Has cursing and mature themes). 
${TracksContentRating.MATURE}: (Has mature themes but no cursing. i.e: clean versions of explicit songs). 
${TracksContentRating.CLEAN}: (No cursing or mature themes). 
${TracksContentRating.UNRATED}: (Has not been rated yet)
`;

// const START_TIME_TOOLTIP = `
//   The earliest time value(i.e: 2.0300) that we can start counting the beats to the entry.
//   The first point of a rhythmic cadence.`;

const initialFormValues: Partial<TrackCreateObject> = {
  active: false,
  album_art_url: '',
  album: '',
  sub_genres: [],
  release_date: undefined,
};

export const TrackModal: FC<TrackModalProps> = (props) => {
  const { actions } = useContext(ActionsContext);
  const { currentlyEditedTrack, isLoading, isBulkTrackActive, beatGrid } = useSelector(selectTrackState);
  const [releaseDate, setReleaseDate] = useState<Dayjs>();
  const [loadedTrack, setTrackToLoad] = useState<File>();
  const [fetchedTrackSize, setFetchedTrackSize] = useState<number>(0);
  const [recommendedGenres, setRecommendedGenres] = useState<string[]>([]);
  const [form] = Form.useForm<TrackCreateObject>();
  const [isSpotifyModalOpen, setSpotifyModalOpenState] = useState<boolean>(false);
  const [isMusicVideoUploading, setMusicVideoUploadingState] = useState<boolean>(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const { isModalOpen = false, onModalOpen, onModalClose } = props;

  const currentTrackBpm = Form.useWatch('bpm', form) || 0;
  const currentDownloadMusicVideoUrl = Form.useWatch('download_url_music_video_file', form) || '';

  const videoClips: VideoClip[] = form.getFieldValue('video_clips') || [];

  const handleSpotifyModal = () => {
    setSpotifyModalOpenState(!isSpotifyModalOpen);
  };

  const handleSpotifyModalTrackSelect = (spotifyTrackResult: SpotifyTrackResult) => {
    setSpotifyModalOpenState(false);
    setRecommendedGenres(spotifyTrackResult.genres);
    form.setFieldsValue({
      title: spotifyTrackResult.title,
      artist: spotifyTrackResult.artist,
      album: spotifyTrackResult.album,
      album_art_url: spotifyTrackResult.album_art_url,
      spotify_id: spotifyTrackResult.spotify_id,
      release_date: dayjs(spotifyTrackResult.release_date).toISOString(),
    });
    setReleaseDate(dayjs(spotifyTrackResult.release_date));
  };

  const handleModalClose = () => {
    setReleaseDate(undefined);
    form.resetFields();
    setTrackToLoad(undefined);
    onModalClose?.();
  };

  const onFinish = async (
    values: Partial<TrackCreateObject & { beat_grid_settings: [] }> | Partial<Track & { beat_grid_settings: [] }>,
  ) => {
    if (values) {
      delete values.beat_grid_settings;

      if (!beatGrid.length) {
        message.error('Missing Beat Grid!');
        return;
      }

      if (!beatGrid.some((i) => i.is_track_entry)) {
        message.error(
          'Every track must have a track as least one track entry. Please double click a beat grid item to create one!',
        );
        return;
      }

      switch (true) {
        case !!isBulkTrackActive: {
          actions.tracks.saveBulkTrack({
            track: {
              ...values,
              id: currentlyEditedTrack?.id,
              download_url_ebu_r128_normalized_file: currentlyEditedTrack?.download_url_ebu_r128_normalized_file,
              download_url_original_file: currentlyEditedTrack?.download_url_original_file,
              download_url_original_mp3_converted_file: currentlyEditedTrack?.download_url_original_mp3_converted_file,
              download_url_peak_normalized_file: currentlyEditedTrack?.download_url_peak_normalized_file,
              md5_file_hash: currentlyEditedTrack?.md5_file_hash,
              stored_filename_ebu_r128_normalized_file: currentlyEditedTrack?.stored_filename_ebu_r128_normalized_file,
              stored_filename_original_file: currentlyEditedTrack?.stored_filename_original_file,
              stored_filename_original_mp3_converted_file:
                currentlyEditedTrack?.stored_filename_original_mp3_converted_file,
              stored_filename_peak_normalized_file: currentlyEditedTrack?.stored_filename_peak_normalized_file,
              size_in_mbs_original_file: fetchedTrackSize,
            },
          });
          break;
        }
        case !!currentlyEditedTrack: {
          actions.tracks.updateTrack({
            ...values,
            beat_grid: {
              create: beatGrid,
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } as any,
            ...(values.video_clips && {
              video_clips: {
                create: values.video_clips,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
              } as any,
            }),
            // video_clips: {
            //   create: values.video_clips,
            //   // eslint-disable-next-line @typescript-eslint/no-explicit-any
            // } as any,
            id: currentlyEditedTrack?.id,
          });
          break;
        }
        default: {
          actions.tracks.saveTrack({
            track: {
              ...(values as TrackCreateObject),
              beat_grid: {
                create: beatGrid,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
              } as any,
            },
            file: loadedTrack as File,
          });
        }
      }
    }
  };

  const onFinishFailed = (errorInfo: ValidateErrorEntity<unknown>) => {
    console.error('Validation Failed:', errorInfo.values);
  };

  const onTrackLoad = useCallback(
    async (duration: number, currentTrackToLoad: File) => {
      if (!currentlyEditedTrack || isBulkTrackActive) {
        setTrackToLoad(currentTrackToLoad);

        const fetchedTrackBlob = await fetch(currentTrackToLoad as unknown as string)
          .then((res) => {
            const reader = res?.body?.getReader();
            return reader?.read();
          })
          .then((data) => new Blob([data?.value as unknown as never]))
          .catch((err) => console.error('FATAL ERROR >', err));

        const resolvedCurrentTrackToLoad = isBulkTrackActive ? fetchedTrackBlob : currentTrackToLoad;
        const trackBufferDetails = await readTrackBufferDetails(resolvedCurrentTrackToLoad as File);
        setFetchedTrackSize(fetchedTrackBlob?.size || 0);

        form.setFieldsValue({
          title: currentlyEditedTrack?.title,
          duration: duration || trackBufferDetails.duration,
          bpm: trackBufferDetails.bpm ? Number(trackBufferDetails.bpm) : undefined,
          key: trackBufferDetails.key,
          sample_rate: trackBufferDetails.sampleRate,
        });

        try {
          const id3 = await readID3Tags(resolvedCurrentTrackToLoad as File);
          console.warn('id3: ', id3);

          form.setFieldsValue({
            bpm: Number(id3.tags['bpm'] || id3.tags['TBPM']?.data || trackBufferDetails.bpm) || undefined,
            artist: id3.tags['artist'] || id3.tags['TPE1']?.data || id3.tags['TPE2']?.data,
            album: id3.tags['album'] || id3.tags['TALB']?.data,
            title: id3.tags['title'] || id3.tags['TIT2']?.data || currentlyEditedTrack?.title,
          });
        } catch (err) {
          console.error('ERROR reading ID3 Tags: ', err);
          message.error('ERROR reading ID3 Tags...');
          form.setFieldsValue({
            bpm: trackBufferDetails.bpm ? Number(trackBufferDetails.bpm) : undefined,
            artist: undefined,
            album: undefined,
            title: undefined,
          });
        }
      }
    },
    [currentlyEditedTrack, form, isBulkTrackActive],
  );

  const onMusicVideoUpload = async (file: File) => {
    if (!currentlyEditedTrack) {
      throw new Error('need to be in edit mode');
    }

    const fileName = `____${currentlyEditedTrack.id}____${file.name}`;
    setMusicVideoUploadingState(true);

    FirebaseService.uploadFile(
      file,
      fileName,
      videosUploadBucket,
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        const roundedProgress = Math.round(Number(progress) * 100) / 100;
        console.info('roundedProgress: ', roundedProgress);
        throttleMessageDisplay(roundedProgress);
      },
      (error) => {
        setMusicVideoUploadingState(false);
        console.error('ERROR > uploadFile:', error);
        message.error(`Error Uploading track! Error: ${error.message}`);
      },
      async (snapshot) => {
        setMusicVideoUploadingState(false);
        // Handle successful uploads on complete
        const metadata = snapshot.metadata;

        const downloadUrl = await FirebaseService.getDownloadUrl(metadata.name, metadata.bucket);

        console.warn('downloadUrl: ', downloadUrl);

        form.setFieldValue('download_url_music_video_file', downloadUrl);

        message.success(
          `${metadata.name} file uploaded successfully to ${metadata.bucket}. here is the download url: ${downloadUrl}`,
          10, // 10 seconds
        );

        await copyTextToClipboard(downloadUrl);
      },
    );
  };

  useEffect(() => {
    if (form && currentlyEditedTrack) {
      form.setFieldsValue({
        ...currentlyEditedTrack,
        release_date: dayjs(currentlyEditedTrack?.release_date).toISOString(),
      });
      setReleaseDate(dayjs(currentlyEditedTrack?.release_date));
    }

    return () => {
      form.resetFields();
      setReleaseDate(undefined);
      setTrackToLoad(undefined);
    };
  }, [currentlyEditedTrack, form]);

  const NeuVybeLogoTypeBW = useMemo(() => new URL('../../assets/neuvybe_logotype_bw.png', import.meta.url), []);

  return (
    <Modal
      closable={!isLoading}
      width="100%"
      open={isModalOpen}
      onOk={onModalOpen}
      onCancel={handleModalClose}
      footer={null}
      style={{
        maxWidth: 'none',
        padding: 0,
        top: 0,
        minHeight: '900px',
        width: '100%',
        margin: 0,
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: '50px 100px',
        }}
      >
        <Image style={{ pointerEvents: 'none' }} preview={false} width={300} src={NeuVybeLogoTypeBW.toString()} />
        <Divider>{`${currentlyEditedTrack ? 'Edit' : 'Create'}`} Track</Divider>
        <Form
          form={form}
          name="basic"
          style={{ maxWidth: 'none', width: '100%' }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          initialValues={initialFormValues}
        >
          {!currentlyEditedTrack && (
            <Form.Item
              label="Raw File"
              valuePropName="fileList"
              rules={[{ required: true, message: 'Must add a raw track to be uploaded' }]}
            >
              <Dragger
                name="file"
                multiple={false}
                maxCount={1}
                beforeUpload={() => false}
                onChange={(info) => {
                  const { status } = info.file;

                  switch (status) {
                    case 'removed': {
                      setTrackToLoad(undefined);
                      break;
                    }
                    case 'uploading': {
                      break;
                    }
                    case 'done': {
                      message.success(`${info.file.name} file uploaded successfully.`);
                      break;
                    }
                    case 'error': {
                      message.error(`${info.file.name} file upload failed.`);
                      break;
                    }
                    default: {
                      if (info.fileList) {
                        const file = info.fileList[0].originFileObj;
                        setTrackToLoad(file);
                      }
                    }
                  }
                }}
                onDrop={(e) => {
                  const file = e.dataTransfer.files[0];
                  setTrackToLoad(file);
                }}
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                <p className="ant-upload-hint">
                  Support for a single upload. Strictly prohibited from uploading company data or other banned files.
                </p>
              </Dragger>
            </Form.Item>
          )}

          {isModalOpen && (
            <AudioPlayer
              trackToLoad={currentlyEditedTrack ? currentlyEditedTrack.download_url_original_file : loadedTrack}
              onTrackLoadCallback={onTrackLoad}
              form={form}
              bpm={currentTrackBpm}
            />
          )}

          <Divider>Track Details</Divider>

          <div style={{ marginBottom: 20 }}>
            <Button onClick={handleSpotifyModal}>Load Details From Spotify</Button>
            &nbsp;&nbsp;&nbsp;
            <Image
              onClick={handleSpotifyModal}
              style={{ cursor: 'pointer' }}
              preview={false}
              width={30}
              src={SpotifyLogoURL}
            />
          </div>

          <SpotifyModal
            isModalOpen={isSpotifyModalOpen}
            onModalClose={handleSpotifyModal}
            onSpotifyTrackSelect={handleSpotifyModalTrackSelect}
          />

          <Form.Item<TrackCreateObject>
            label="Title"
            name="title"
            rules={[{ required: true, message: 'Please enter a title!' }]}
          >
            <Input placeholder="Title" />
          </Form.Item>

          <Form.Item<TrackCreateObject>
            label="Artist"
            name="artist"
            rules={[{ required: true, message: 'Please enter an artist!' }]}
          >
            <Input placeholder="Artist" />
          </Form.Item>

          <Form.Item<TrackCreateObject>
            label="Album"
            name="album"
            rules={[{ required: false, message: 'Please enter an album!' }]}
          >
            <Input placeholder="Album" />
          </Form.Item>

          <Form.Item<TrackCreateObject>
            label="Album Art URL"
            name="album_art_url"
            rules={[{ required: false, message: 'Please enter an album art url!' }]}
          >
            <Input placeholder="https://i.scdn.co/image/ab67616d0000b273f54b99bf27cda88f4a7403ce" />
          </Form.Item>

          {!!currentlyEditedTrack && currentlyEditedTrack.id && (
            <Form.Item<TrackCreateObject>
              label="Music Video URL (GCS downloadable url)"
              name="download_url_music_video_file"
              rules={[{ required: false, message: 'Please enter a music video url!' }]}
            >
              {currentDownloadMusicVideoUrl ? (
                <Input placeholder="https://firebasestorage.googleapis.com/...." />
              ) : (
                <Button
                  disabled={isMusicVideoUploading}
                  style={{ cursor: 'pointer' }}
                  onClick={() => setIsDrawerOpen(true)}
                >
                  Click to Upload Custom Music Video File To Cloud &nbsp;
                  <CloudUploadOutlined />
                </Button>
              )}
            </Form.Item>
          )}

          {!!videoClips.length && (
            <div style={{ marginBottom: '25px' }}>
              <h3>Video Clips</h3>
              <Flex wrap align="center" style={{ width: '100%' }}>
                {videoClips.map((videoClip) => (
                  <Flex align="center" vertical style={{ marginRight: '10px' }}>
                    <h5>{videoClip.device_size}</h5>
                    <video
                      loop
                      style={{ position: 'relative', display: 'flex', width: 200, height: 'auto' }}
                      src={videoClip.file_path}
                      controls
                    />
                  </Flex>
                ))}
              </Flex>
            </div>
          )}

          <Form.Item
            label="Primary Genres"
            name="primary_genres"
            rules={[{ required: true, message: 'Must have at least 1 genre!' }]}
          >
            <Select mode="multiple" allowClear placeholder="Select Genre/s">
              {Object.entries(genreDisplayNames).map(([genreKey, genre]) => (
                <Select.Option key={genreKey} value={genreKey}>
                  {genre}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          {recommendedGenres?.length > 0 && (
            <div>
              <p style={{ display: 'inline-flex' }}>Recommended Genres:&nbsp;</p>
              <Space style={{ marginBottom: 5, display: 'inline-flex' }} wrap>
                {recommendedGenres?.map((genre) => <Tag key={genre}>{genre}</Tag>)}
              </Space>
            </div>
          )}

          <Form.Item
            label="Sub Genres"
            name="sub_genres"
            rules={[{ required: false, message: 'Must have at least 1 genre!' }]}
          >
            <Select mode="multiple" allowClear placeholder="Select Genre/s">
              {Object.entries(genreDisplayNames).map(([genreKey, genre]) => (
                <Select.Option key={genreKey} value={genreKey}>
                  {genre}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item<TrackCreateObject>
            label="Duration"
            name="duration"
            rules={[{ required: true, message: 'Please enter a duration!' }]}
          >
            <InputNumber style={{ minWidth: '200px' }} placeholder="Duration" min={4} max={3600} precision={4} />
          </Form.Item>

          <Form.Item<TrackCreateObject>
            label="Release Date"
            name="release_date"
            rules={[{ required: true, message: 'Please enter a Release Date!' }]}
          >
            <div>
              <DatePicker
                style={{ minWidth: '200px' }}
                placeholder="Release Date"
                value={releaseDate}
                format={'MM/YYYY'}
                onChange={(dayjsObj) => {
                  if (dayjsObj) {
                    setReleaseDate(dayjsObj);
                    form.setFieldValue('release_date', dayjsObj?.toISOString());
                  } else {
                    setReleaseDate(undefined);
                    form.setFieldValue('release_date', undefined);
                  }
                }}
                picker="month"
              />
            </div>
          </Form.Item>

          <Form.Item<TrackCreateObject>
            label="BPM"
            name="bpm"
            rules={[{ required: true, message: 'Please enter a bpm!' }]}
          >
            <InputNumber style={{ minWidth: '200px' }} placeholder="BPM" min={30} max={300} precision={2} />
          </Form.Item>

          <Form.Item<TrackCreateObject> label="Sample Rate" name="sample_rate">
            <InputNumber style={{ minWidth: '200px' }} placeholder="Sample Rate" precision={0} />
          </Form.Item>

          <Form.Item label="Key" name="key">
            <Select placeholder="Select Musical Key">
              {Object.entries(CamelotKeys).map(([itemKey, val]) => (
                <Select.Option key={itemKey} value={itemKey}>
                  {val}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item<TrackCreateObject> label="Active" name="active" valuePropName="checked">
            <Switch />
          </Form.Item>

          <Form.Item
            label="Content Rating"
            name="content_rating"
            tooltip={CONTENT_RATING_TOOLTIP}
            rules={[{ required: true, message: 'Please enter a rating!' }]}
          >
            <Select placeholder="Content Rating">
              <Select.Option value={TracksContentRating.EXPLICIT}>{TracksContentRating.EXPLICIT}</Select.Option>
              <Select.Option value={TracksContentRating.MATURE}>{TracksContentRating.MATURE}</Select.Option>
              <Select.Option value={TracksContentRating.CLEAN}>{TracksContentRating.CLEAN}</Select.Option>
              <Select.Option value={TracksContentRating.UNRATED}>{TracksContentRating.UNRATED}</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item style={{ marginTop: '50px', textAlign: 'center' }}>
            <Button loading={isLoading} type="primary" htmlType="submit">
              {`${currentlyEditedTrack ? 'Update' : 'Save'}`}
            </Button>
          </Form.Item>
        </Form>
      </div>
      <Drawer title="Upload Music Video" onClose={() => setIsDrawerOpen(false)} open={isDrawerOpen}>
        <Flex align="center" justify="center" gap="middle" wrap>
          <Upload
            disabled={isMusicVideoUploading}
            listType="picture-card"
            showUploadList={false}
            maxCount={1}
            accept="video/*"
            beforeUpload={() => false}
            onChange={(info) => onMusicVideoUpload(info.file as unknown as File)}
          >
            Drag file or click here to upload
          </Upload>
        </Flex>
      </Drawer>
    </Modal>
  );
};
