import { guess, analyze } from 'web-audio-beat-detector';
import jsmediatags from '../external/lib/jsmediatags/jsmediatags.min.js';
import { getCamelotBasedMusicalKey } from './essentia';
import { CamelotCode } from './CamelotWheel.js';
import throttle from 'lodash/throttle';
import { message } from 'antd';

interface ID3 {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  tags: Record<string, any>;
}

export const readID3Tags = (track: Blob): Promise<ID3> => {
  return new Promise((resolve, reject) => {
    jsmediatags.read(track, {
      onSuccess: async (id3: ID3) => resolve(id3),
      onError: (error: { type: string; info: string }) => reject(error),
    });
  });
};

export const readTrackBufferDetails = (track: Blob): Promise<AnalyzedTrackBufferDetails> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      //TODO: refactor to use promise / async await
      const arrayBuffer = reader.result as ArrayBuffer;

      audioContextDecoder(arrayBuffer)
        .then(resolve)
        .catch((err) => {
          console.error('ERROR: readTrackBufferDetails > ', err);
          reject(err);
        });
    };

    reader.readAsArrayBuffer(track);
  });
};

interface AnalyzedTrackBufferDetails {
  duration: number;
  length: number;
  numberOfChannels: number;
  sampleRate: number;
  tempo: number;
  bpm: number;
  key?: CamelotCode;
  offset: number;
  buffer: AudioBuffer;
}

export const audioContextDecoder = (arrayBufferToDecode: ArrayBuffer): Promise<AnalyzedTrackBufferDetails> => {
  return new Promise((resolve, reject) => {
    window.audioContext.decodeAudioData(
      arrayBufferToDecode,
      (buffer) => {
        const { duration, length, numberOfChannels, sampleRate } = buffer;

        Promise.all([analyze(buffer), guess(buffer), getCamelotBasedMusicalKey(buffer)])
          .then(([tempo, { bpm, offset }, key]) => {
            const valuableBufferDetails = {
              duration,
              length,
              numberOfChannels,
              sampleRate,
              tempo,
              bpm,
              key,
              offset,
              buffer,
            };

            resolve(valuableBufferDetails);
          })
          .catch((err) => {
            console.error('ERROR: audioContextDecoder > audioContext.decodeAudioData > ', err);
            reject(err);
          });
      },
      (err) => {
        console.error('err > decodeAudioData > ', err.message);
        reject(err);
      },
    );
  });
};

export const convertBytesToMBs = (bytes: string | number, precision = 10000) =>
  Math.round(Number(bytes) * 0.000001 * precision) / precision;

export const getBpmFromSpb = (bps: number): number => {
  if (bps === 0) {
    return 0;
  }
  return Math.round((60 / bps) * 1000000) / 1000000;
};

export const getSPB = (bpm: number): number => {
  if (bpm === 0) {
    return 0;
  }
  return Math.round((60 / bpm) * 1000000) / 1000000;
};

export const getBPS = (bpm: number): number => {
  if (bpm === 0) {
    return 0;
  }
  return Math.round((bpm / 60) * 1000000) / 1000000;
};

export const findClosestNumber = (numbers: number[], target: number): number => {
  if (numbers.length === 0) {
    throw new Error('The array is empty');
  }

  return numbers.reduce((closest, current) => {
    return Math.abs(current - target) < Math.abs(closest - target) ? current : closest;
  });
};

export const findClosestNumberAndIndex = (numbers: number[], target: number): { value: number; index: number } => {
  if (numbers.length === 0) {
    throw new Error('The array is empty');
  }

  return numbers.reduce(
    (closest, current, index) => {
      if (Math.abs(current - target) < Math.abs(closest.value - target)) {
        return { value: current, index };
      }
      return closest;
    },
    { value: numbers[0], index: 0 },
  );
};

export const throttleMessageDisplay = throttle((roundedProgress: number) => {
  message.info(`Uploading track... ${roundedProgress}%`);
}, 1000);

export const copyTextToClipboard = async (text: string) => {
  try {
    await navigator.clipboard.writeText(text);
    console.log('Text copied to clipboard');
  } catch (err) {
    console.error('Failed to copy text: ', err);
  }
};
