import { createSlice } from '@reduxjs/toolkit';
import { TablePaginationConfig, message } from 'antd';
import type { PayloadAction } from '@reduxjs/toolkit';
import { Epic, ofType } from 'redux-observable';
import { switchMap, catchError } from 'rxjs/operators';
import type { RootAction, RootState } from '../../services/ReduxService/store';
import { Track, TracksResponse, TrackCreateObject, SpotifyTrackResult, BulkUploadTrackCreateObject } from './types';
import { BeatGrid } from '../../models';
import { ApiService } from '../../utils/ApiService';
import { of, concat, from, debounceTime } from 'rxjs';
import { FilterValue, Key, SorterResult } from 'antd/es/table/interface';
import { processTrackUploading } from './helpers/processTrackUploading';
import { AxiosError } from 'axios';

// Define a type for the slice state
export interface TracksState {
  tracks: Track[];
  spotifyTrackResults: SpotifyTrackResult[];
  isLoading: boolean;
  spotifySearchString: string;
  searchString: string;
  selectedRowKeys: Key[];
  pagination: TablePaginationConfig;
  filters: Record<string, FilterValue | null>;
  sorter: SorterResult<Track>;
  isTrackModalOpen: boolean;
  currentlyEditedTrack?: Track;
  currentTrackTime: number;
  beatGrid: BeatGrid[];
  isBulkTrackActive: boolean;
  isVybeCollectionDrawerOpen: boolean;
}

// Define the initial state using that type
export const initialState: TracksState = {
  tracks: [],
  spotifyTrackResults: [],
  isLoading: false,
  spotifySearchString: '',
  searchString: '',
  selectedRowKeys: [],
  pagination: {
    current: 1,
    pageSize: 50,
    total: 0,
    position: ['topRight', 'bottomRight'],
  },
  filters: {
    primary_genres: [],
  },
  sorter: {
    column: undefined,
    columnKey: undefined,
    field: 'created_at',
    order: undefined,
  },
  isTrackModalOpen: false,
  currentlyEditedTrack: undefined,
  currentTrackTime: 0,
  beatGrid: [],
  isBulkTrackActive: false,
  isVybeCollectionDrawerOpen: false,
};

export const trackSlice = createSlice({
  name: 'tracks',
  initialState,
  reducers: {
    fetchSpotifyTracks: (state) => state,
    fetchTracks: (state) => state,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    deleteTracks: (state, action: PayloadAction<number[]>) => state,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    deleteBulkTracks: (state, action: PayloadAction<number[]>) => state,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    saveTrack: (state, action: PayloadAction<{ track: Partial<TrackCreateObject>; file: File }>) => state,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    saveBulkTrack: (state, action: PayloadAction<{ track: Partial<BulkUploadTrackCreateObject> }>) => state,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    updateTrack: (state, action: PayloadAction<Partial<Track>>) => state,
    setTracks: (state, action: PayloadAction<Track[]>) => {
      state.tracks = action.payload;
    },
    setSpotifyTrackResults: (state, action: PayloadAction<SpotifyTrackResult[]>) => {
      state.spotifyTrackResults = action.payload;
    },
    setLoadingState: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setSearchString: (state, action: PayloadAction<string>) => {
      state.searchString = action.payload;
    },
    setSpotifySearchString: (state, action: PayloadAction<string>) => {
      state.spotifySearchString = action.payload;
    },
    setSelectedRowKeys: (state, action: PayloadAction<Key[]>) => {
      state.selectedRowKeys = action.payload;
    },
    setPagination: (state, action: PayloadAction<TablePaginationConfig>) => {
      state.pagination = action.payload;
    },
    setSorter: (state, action: PayloadAction<SorterResult<Track>>) => {
      state.sorter = {
        ...state.sorter,
        ...action.payload,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } as any;
    },
    setFilters: (state, action: PayloadAction<Record<string, FilterValue | null>>) => {
      state.filters = {
        ...state.filters,
        ...action.payload,
      };
    },
    setTrackModalVisibilityState: (state, action: PayloadAction<boolean>) => {
      state.isTrackModalOpen = action.payload;
    },
    setCurrentlyEditedTrack: (state, action: PayloadAction<Track | undefined>) => {
      state.currentlyEditedTrack = action.payload;
    },
    setCurrentTrackTime: (state, action: PayloadAction<number>) => {
      state.currentTrackTime = action.payload;
    },
    setBeatGrid: (state, action: PayloadAction<BeatGrid[]>) => {
      state.beatGrid = action.payload;
    },
    setBulkTrackActiveState: (state, action: PayloadAction<boolean>) => {
      state.isBulkTrackActive = action.payload;
    },
    setVybeCollectionDrawerVisibilityState: (state, action: PayloadAction<boolean>) => {
      state.isVybeCollectionDrawerOpen = action.payload;
    },
  },
});

export const trackActions = trackSlice.actions;
export const {
  fetchSpotifyTracks,
  fetchTracks,
  setTracks,
  setSpotifyTrackResults,
  deleteTracks,
  deleteBulkTracks,
  saveTrack,
  saveBulkTrack,
  updateTrack,
  setLoadingState,
  setSearchString,
  setSpotifySearchString,
  setSelectedRowKeys,
  setPagination,
  setSorter,
  setTrackModalVisibilityState,
  setCurrentlyEditedTrack,
  setCurrentTrackTime,
  setBeatGrid,
  setBulkTrackActiveState,
  setVybeCollectionDrawerVisibilityState,
} = trackSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectTrackState = (state: RootState) => state.tracks;

export default trackSlice.reducer;

export type SortOrder = 'asc' | 'desc';

export const onSearchSpotifyTracksEpic: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    ofType(setSpotifySearchString.type),
    debounceTime(350),
    switchMap(() => {
      const filterOptions = {
        query: state$.value.tracks.spotifySearchString,
      };

      if (!filterOptions.query) {
        return of(setSpotifyTrackResults([]));
      }

      return concat(
        of(setLoadingState(true)),
        from(
          ApiService.getAllCollectionValues<SpotifyTrackResult[]>('spotify', filterOptions).then((data) => data.data),
        ).pipe(
          switchMap((data) => {
            return [setSpotifyTrackResults(data)];
          }),
        ),
        of(setLoadingState(false)),
      );
    }),
    catchError((err: Error) => {
      console.error('FATAL ERROR: err > tracks > onSearchSpotifyTracksEpic: ', err);
      message.error(err.message);
      return of(setLoadingState(false));
    }),
  );

export const onFetchTracksEpic: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    ofType(fetchTracks.type),
    switchMap(() => {
      const filterOptions = {
        page: state$.value.tracks.pagination.current,
        limit: state$.value.tracks.pagination.pageSize,
        order_by: state$.value.tracks.sorter.field,
        sort: state$.value.tracks.sorter.order === 'ascend' ? 'asc' : 'desc',
        query: state$.value.tracks.searchString,
        ...(state$.value.tracks.filters ? state$.value.tracks.filters : {}),
      };

      const isBulkTrackActive = state$.value.tracks.isBulkTrackActive;
      const resolvedAPIPath = isBulkTrackActive ? 'bulk-tracks' : 'tracks';

      return concat(
        of(setLoadingState(true)),
        from(
          ApiService.getAllCollectionValues<TracksResponse>(resolvedAPIPath, filterOptions).then((data) => data.data),
        ).pipe(
          switchMap((data) => {
            return [
              setTracks(data.data),
              setPagination({ ...state$.value.tracks.pagination, total: data.meta.total_count }),
            ];
          }),
        ),
        of(setLoadingState(false)),
      );
    }),
    catchError((err: Error) => {
      console.error('FATAL ERROR: err > tracks > OnFetchTracksEpic: ', err);
      message.error(err.message);
      return of(setLoadingState(false));
    }),
  );

export const onSearchStringChangeEpic: Epic<RootAction, RootAction, RootState> = (action$) =>
  action$.pipe(
    ofType(setSearchString.type),
    debounceTime(350),
    switchMap(() => of(fetchTracks())),
    catchError((err: Error) => {
      console.error('FATAL ERROR: err > tracks > onSearchStringChangeEpic: ', err);
      message.error(err.message);
      return of(setLoadingState(false));
    }),
  );

export const onDeleteTracksEpic: Epic<RootAction, RootAction, RootState> = (action$) =>
  action$.pipe(
    ofType(deleteTracks.type),
    switchMap((action) => {
      const ids = action.payload;
      return concat(
        of(setLoadingState(true)),
        from(ApiService.doGenericDeleteMany('tracks', ids).then((data) => data.data)).pipe(
          switchMap(() => of(fetchTracks())),
        ),
        of(setLoadingState(false)),
      );
    }),
    catchError((err: Error) => {
      console.error('FATAL ERROR: err > tracks > onDeleteTracksEpic: ', err);
      message.error(err.message);
      return of(setLoadingState(false));
    }),
  );

export const onDeleteBulkTracksEpic: Epic<RootAction, RootAction, RootState> = (action$) =>
  action$.pipe(
    ofType(deleteBulkTracks.type),
    switchMap((action) => {
      const ids = action.payload;

      return concat(
        of(setLoadingState(true)),
        from(ApiService.doGenericDeleteMany('bulk-tracks', ids).then((data) => data.data)).pipe(
          switchMap(() => of(fetchTracks())),
        ),
        of(setLoadingState(false)),
      );
    }),
    catchError((err: Error) => {
      console.error('FATAL ERROR: err > tracks > onDeleteBulkTracksEpic: ', err);
      message.error(err.message);
      return of(setLoadingState(false));
    }),
  );

export const onTrackEditModalCloseEpic: Epic<RootAction, RootAction, RootState> = (action$) =>
  action$.pipe(
    ofType(setTrackModalVisibilityState.type),
    switchMap(({ payload }) => {
      const isHidingModal = !payload;
      return isHidingModal ? of(setCurrentlyEditedTrack(undefined)) : of();
    }),
    catchError((err: Error) => {
      console.error('FATAL ERROR: err > tracks > onTrackEditModalCloseEpic: ', err);
      message.error(err.message);
      return of(setLoadingState(false));
    }),
  );

export const onSaveTrackFromBulkTrackEpic: Epic<RootAction, RootAction, RootState> = (action$) =>
  action$.pipe(
    ofType(saveBulkTrack.type),
    switchMap((action) => {
      const track = action.payload.track;
      return concat(
        of(setLoadingState(true)),
        from(ApiService.doGenericPost('tracks', track)).pipe(
          switchMap(() => {
            message.success('Track saved from bulk track successfully!');
            // Only close the modal on successful track upload.
            return of(deleteBulkTracks([track.id as number]), setTrackModalVisibilityState(false));
          }),
          catchError((err: Error) => {
            console.error('FATAL ERROR: err > tracks > onSaveBulkTrackEpic: ', err);
            message.error(err.message);
            // Ensure trackModalVisibilityState remains true on error
            return of(setLoadingState(false), setTrackModalVisibilityState(true));
          }),
        ),
      );
    }),
    catchError((err: Error) => {
      console.error('FATAL ERROR: err > tracks > onSaveBulkTrackEpic: ', err);
      message.error(err.message);
      // Ensure trackModalVisibilityState remains true on error
      return of(setLoadingState(false), setTrackModalVisibilityState(true));
    }),
  );

export const onSaveTrackEpic: Epic<RootAction, RootAction, RootState> = (action$) =>
  action$.pipe(
    ofType(saveTrack.type),
    switchMap((action) => {
      const track = action.payload.track;
      const file = action.payload.file;
      return concat(
        of(setLoadingState(true)),
        from(processTrackUploading(track, file)).pipe(
          switchMap(() => {
            message.success('Track saved successfully!');
            // Only close the modal on successful track upload.
            return of(fetchTracks(), setTrackModalVisibilityState(false));
          }),
          catchError((err: Error) => {
            console.error('FATAL ERROR: err > tracks > onSaveTrackEpic: ', err);
            message.error(err.message);
            // Ensure trackModalVisibilityState remains true on error
            return of(setLoadingState(false), setTrackModalVisibilityState(true));
          }),
        ),
      );
    }),
    catchError((err: Error) => {
      console.error('FATAL ERROR: err > tracks > onSaveTrackEpic: ', err);
      message.error(err.message);
      // Ensure trackModalVisibilityState remains true on error
      return of(setLoadingState(false), setTrackModalVisibilityState(true));
    }),
  );

export const onUpdateTrackEpic: Epic<RootAction, RootAction, RootState> = (action$) =>
  action$.pipe(
    ofType(updateTrack.type),
    switchMap((action) => {
      const track = action.payload;
      if (!track.id && track.id !== 0) {
        throw new Error('Track ID is required to update a track!');
      }

      return concat(
        of(setLoadingState(true)),
        from(ApiService.doGenericPatch('tracks', track.id, track)).pipe(
          switchMap(() => {
            message.success('Track updated successfully!');
            // Only close the modal on successful track upload.
            return of(fetchTracks(), setTrackModalVisibilityState(false));
          }),
          catchError((err: AxiosError<{ errors: string }>) => {
            console.error('FATAL ERROR: err > tracks > onUpdateTrackEpic: ', err);
            message.error(err.response?.data?.errors || err.message);
            // Ensure trackModalVisibilityState remains true on error
            return of(setLoadingState(false), setTrackModalVisibilityState(true));
          }),
        ),
      );
    }),
    catchError((err: AxiosError<{ errors: string }>) => {
      console.error('FATAL ERROR: err > tracks > onUpdateTrackEpic: ', err);
      message.error(err.response?.data?.errors || err.message);
      // Ensure trackModalVisibilityState remains true on error
      return of(setLoadingState(false), setTrackModalVisibilityState(true));
    }),
  );
