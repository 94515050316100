import { FC, useState, useMemo, useContext, useEffect } from 'react';
import { Modal, Button, Form, Input, Divider, Image, Switch, Select, List, Avatar, message } from 'antd';
import { ValidateErrorEntity } from 'rc-field-form/lib/interface';
import { useSelector } from 'react-redux';
import { selectVybeCollectionState } from '../vybeCollectionsSlice';
import { ActionsContext } from '../../../Actions';
import { VybeCollection, VybeCollectionCreateObject } from '../types';
import { Track } from 'containers/Tracks/types';

interface VybeCollectionModalProps {
  isModalOpen?: boolean;
  onModalOpen?: () => void;
  onModalClose?: () => void;
}

// interface CreateVybeCollectionFormValues {
//   name: string;
//   description?: string;
//   active?: boolean;
//   track_ids: { value: string }[];
// }

const formatTrackSearchResults = (tracks: Track[]) => {
  return tracks.map((track) => ({
    value: track.id.toString(),
    label: `${track.title}, ${track.artist} - ${track.bpm} - ${track.content_rating}`,
  }));
};

export const VybeCollectionModal: FC<VybeCollectionModalProps> = (props) => {
  const { actions } = useContext(ActionsContext);
  const { isLoading, trackSearchResults, currentlyEditedVybeCollection } = useSelector(selectVybeCollectionState);
  const [form] = Form.useForm<VybeCollectionCreateObject>();
  const [selectedTracks, setSelectedTracks] = useState<Track[]>([]);
  const { isModalOpen = false, onModalOpen, onModalClose } = props;

  const isEditing = !!currentlyEditedVybeCollection;

  const filteredTrackSearchResults = useMemo(() => {
    return trackSearchResults.filter((x) => !selectedTracks.map((s) => s.id).includes(x.id));
  }, [selectedTracks, trackSearchResults]);

  const initialFormValues: Partial<VybeCollection> = {
    name: currentlyEditedVybeCollection?.name || '',
    description: currentlyEditedVybeCollection?.description || '',
    active: currentlyEditedVybeCollection?.active || false,
  };

  const handleModalClose = () => {
    form.resetFields();
    onModalClose?.();
  };

  const onFinish = async (values: VybeCollectionCreateObject) => {
    const resolvedValues = {
      ...values,
      track_ids: selectedTracks.map(({ id }) => id) || [],
    };

    if (values) {
      if (currentlyEditedVybeCollection) {
        actions.vybeCollections.updateVybeCollection({
          ...resolvedValues,
          id: currentlyEditedVybeCollection.id,
        });
      } else {
        await actions.vybeCollections.saveVybeCollection(resolvedValues);
      }
    }
  };

  const onFinishFailed = (errorInfo: ValidateErrorEntity<unknown>) => {
    console.error('Validation Failed:', errorInfo.values);
  };

  const onSearchTrack = (value: string) => {
    actions.vybeCollections.setTrackSearchString(value);
  };

  const onSelectTrack = (track: Track) => {
    setSelectedTracks([...selectedTracks, track]);
  };

  const onRemoveTrack = (track: Track) => {
    setSelectedTracks(selectedTracks.filter((x) => x.id !== track.id));
  };

  useEffect(() => {
    if (form && currentlyEditedVybeCollection) {
      const currentlyAddedTracks = (currentlyEditedVybeCollection?.vybe_collection_tracks || []).map((x) => x.tracks);
      setSelectedTracks(currentlyAddedTracks);
    }

    return () => {
      form.resetFields();
    };
  }, [currentlyEditedVybeCollection, form]);

  const validateVybeTracksExist = () => {
    if (selectedTracks.length >= 3) {
      return Promise.resolve();
    }
    return Promise.reject(new Error('Must have at least 3 tracks in a Vybe Collection!'));
  };

  const NeuVybeLogoTypeBW = useMemo(() => new URL('../../../assets/neuvybe_logotype_bw.png', import.meta.url), []);

  return (
    <Modal
      closable={!isLoading}
      width="100%"
      open={isModalOpen}
      onOk={onModalOpen}
      onCancel={handleModalClose}
      footer={null}
      style={{
        maxWidth: 'none',
        padding: 0,
        top: 0,
        minHeight: '900px',
        width: '100%',
        margin: 0,
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: '50px 100px',
        }}
      >
        <Image style={{ pointerEvents: 'none' }} preview={false} width={300} src={NeuVybeLogoTypeBW.toString()} />
        <Divider>{isEditing ? 'Edit' : 'Create'} Vybe Collection</Divider>
        <Form
          form={form}
          name="basic"
          style={{ maxWidth: 'none', width: '100%' }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          initialValues={initialFormValues}
        >
          <Form.Item<VybeCollectionCreateObject>
            label="Name"
            name="name"
            rules={[{ required: true, message: 'Please enter a name!' }]}
          >
            <Input placeholder="Name" />
          </Form.Item>

          <Form.Item<VybeCollectionCreateObject>
            label="Description"
            name="description"
            rules={[{ required: false, message: 'Please enter a description!' }]}
          >
            <Input placeholder="Description" />
          </Form.Item>

          <Form.Item<VybeCollectionCreateObject> label="Active" name="active" valuePropName="checked">
            <Switch />
          </Form.Item>

          <Divider>Add Tracks To Vybe Collection</Divider>

          <Form.Item name="track_ids" label="Tracks" rules={[{ validator: validateVybeTracksExist }]}>
            <Select
              // labelInValue
              style={{ width: '100%' }}
              filterOption={false}
              options={formatTrackSearchResults(filteredTrackSearchResults)}
              allowClear
              showSearch
              onSelect={(value) => {
                const foundTrack = trackSearchResults.find((x) => x.id === parseInt(value));
                if (foundTrack) {
                  onSelectTrack(foundTrack);
                } else {
                  message.error('Track not found in list of search results!');
                }
              }}
              // onSelect={onSelectTrack}
              onSearch={onSearchTrack}
              placeholder="Track Search"
            />
          </Form.Item>
          <br />

          <List
            itemLayout="horizontal"
            dataSource={selectedTracks}
            rowKey="id"
            renderItem={(item) => {
              return (
                <List.Item
                  actions={[
                    <a key="list-loadmore-edit" onClick={() => onRemoveTrack(item)}>
                      Remove
                    </a>,
                  ]}
                >
                  <List.Item.Meta
                    avatar={<Avatar size="large" src={item.album_art_url} />}
                    title={
                      <a target="_blank" rel="noreferrer" href={item.download_url_original_file}>
                        {item.title}
                      </a>
                    }
                    description={`${item.artist} - ${item.album} - ${item.bpm} - ${item.content_rating}`}
                  />
                </List.Item>
              );
            }}
          />

          <Form.Item style={{ marginTop: '50px', textAlign: 'center' }}>
            <Button loading={isLoading} type="primary" htmlType="submit">
              {`${currentlyEditedVybeCollection ? 'Update' : 'Save'}`}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};
