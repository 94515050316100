import { FirebaseService } from 'utils/firebase';
import { TrackCreateObject } from '../types';
import { message } from 'antd';
import { convertBytesToMBs, throttleMessageDisplay } from 'utils';
import { tracksBucket } from 'utils/config';

export const processTrackUploading = (track: Partial<TrackCreateObject>, file: File): Promise<TrackCreateObject> => {
  return new Promise((resolve, reject) => {
    const fileName = file.name;

    FirebaseService.uploadFile(
      file,
      fileName,
      tracksBucket,
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        const roundedProgress = Math.round(Number(progress) * 100) / 100;
        console.info('roundedProgress: ', roundedProgress);
        throttleMessageDisplay(roundedProgress);
      },
      (error) => {
        console.error('ERROR > uploadFile:', error);
        message.error(`Error Uploading track! Error: ${error.message}`);
        reject(error);
      },
      async (snapshot) => {
        // Handle successful uploads on complete
        const metadata = snapshot.metadata;
        console.warn('metadata: ', metadata);
        // const downloadURL = await FirebaseService.getDownloadUrl(fileName);
        // console.warn('downloadURL: ', downloadURL);
        const size_in_mbs_original_file = convertBytesToMBs(file.size) || convertBytesToMBs(metadata.size);
        const md5_file_hash = metadata.md5Hash;
        const normalizeAndSaveNewlyAddedTracks = FirebaseService.getFunctions<
          { stored_filename_original_file: string; track: Partial<TrackCreateObject> },
          Partial<TrackCreateObject>
        >('normalizeAndSaveNewlyAddedTracks');

        try {
          const normalizeAndSaveNewlyAddedTracksResult = await normalizeAndSaveNewlyAddedTracks({
            stored_filename_original_file: fileName,
            track: {
              ...track,
              md5_file_hash,
              size_in_mbs_original_file,
            },
          });

          // Read result of the Cloud Function.
          console.warn('normalizeAndSaveNewlyAddedTracksResult: ', normalizeAndSaveNewlyAddedTracksResult);

          resolve({
            ...track,
            md5_file_hash: metadata.md5Hash,
            size_in_mbs_original_file,

            download_url_original_file: normalizeAndSaveNewlyAddedTracksResult.data.download_url_original_file,
            download_url_original_mp3_converted_file:
              normalizeAndSaveNewlyAddedTracksResult.data.download_url_original_mp3_converted_file,
            download_url_ebu_r128_normalized_file:
              normalizeAndSaveNewlyAddedTracksResult.data.download_url_ebu_r128_normalized_file,
            download_url_peak_normalized_file:
              normalizeAndSaveNewlyAddedTracksResult.data.download_url_peak_normalized_file,

            stored_filename_original_file:
              normalizeAndSaveNewlyAddedTracksResult.data.stored_filename_original_mp3_converted_file,
            stored_filename_original_mp3_converted_file:
              normalizeAndSaveNewlyAddedTracksResult.data.stored_filename_original_mp3_converted_file,
            stored_filename_ebu_r128_normalized_file:
              normalizeAndSaveNewlyAddedTracksResult.data.stored_filename_ebu_r128_normalized_file,
            stored_filename_peak_normalized_file:
              normalizeAndSaveNewlyAddedTracksResult.data.stored_filename_peak_normalized_file,
          } as TrackCreateObject);
        } catch (error) {
          console.error('ERROR > normalizeAndSaveNewlyAddedTracks:', error);
          reject(error);
        }
      },
    );
  });
};
